import { env } from "@/config";
import * as yup from "yup";

export interface ISchemaLogin {
	requiredLogin: boolean;
}
export const schema = () =>
	yup.object().shape({
		username: yup.string().required().label("Nombre de usuario requerido"),
		password: yup.string().min(4).required().label("Ingrese contraseña"),
		extension: env.onlyCallcenter ?
			yup.string()
				.matches(/^[0-9]{3,5}$/, "Ingrese un código válido")
				.required("Extensión requerida para login en CallCenter") : 
			yup.string().when("loginCallCenter", {
				is: true,
				then: schema =>
					schema
						.matches(/^[0-9]{3,5}$/, "Ingrese un código válido")
						.required("Extensión requerida para login en CallCenter"),
				otherwise: schema => schema.min(0),
		}),
	});

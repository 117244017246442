import * as yup from "yup";
import { Field } from "@/types/components";
import { ConditionConfig } from "yup/lib/Condition";

yup.addMethod(
	yup.string,
	"min",
	function (
		min: number,
		errorMessage = `Ingrese por lo menos ${min} caracteres`
	) {
		return this.test(`min`, errorMessage, function (value = "") {
			const { path, createError } = this;
			if (!value) return true;
			const valid = new RegExp(`.{${min},}`).test(value);
			return (
				valid ||
				createError({
					path,
					message: errorMessage,
				})
			);
		});
	}
);
yup.addMethod(
	yup.string,
	"max",
	function (max: number, errorMessage = `No ingrese más de ${max} caracteres`) {
		return this.test(`max`, errorMessage, function (value = "") {
			const { path, createError } = this;
			const valid = new RegExp(`^.{0,${max}}$`).test(value);
			return (
				valid ||
				createError({
					path,
					message: errorMessage,
				})
			);
		});
	}
);

export function getYupField(
	field: Field,
	validations: Required<Field>["validate"]
): yup.StringSchema {
	return Object.entries(validations).reduce(
		(acc, [key, value]) => {
			if (key === "regex" && Array.isArray(value)) {
				const params = value.slice();
				return acc["matches"](new RegExp(params.shift() as string), ...params);
			} else if (key === "when" && Array.isArray(value)) {
				const condition = value[1] as ConditionConfig<any>;
				console.log(key, value);
				return acc["when"](value[0], {
					is:
						typeof condition.is === "object"
							? (value: any) => {
									const val = condition.is.key
										? value[condition.is.key]
										: value;
									if (condition.is.operator === "$gte")
										return val >= condition.is.value;
									else if (condition.is.operator === "$gt")
										return val > condition.is.value;
									else if (condition.is.operator === "$lte")
										return val <= condition.is.value;
									else if (condition.is.operator === "$lt")
										return val < condition.is.value;
									return val === condition.is.value;
							  }
							: condition.is,
					then: getYupField(field, condition.then as any),
					otherwise: condition.otherwise
						? getYupField(field, condition.otherwise as any)
						: undefined,
				});
			}
			return acc[key](...(Array.isArray(value) ? value : [value]));
		},
		field.type === "number"
			? yup.number().transform(val => (Number.isNaN(val) ? null : val))
			: (yup[
					field.type === "date" || field.type === "datetime" ? "date" : "string"
			  ]() as any)
	);
}

export const generateDynamicSchema = (fields: Field[]) => {
	const schema: { [k: string]: any } = {};
	fields.forEach(field => {
		if (!field.validate) return;
		const { required, ...validations } = field.validate;
		const validator = getYupField(field, validations);
		const req =
			typeof required === "string"
				? required
				: `El campo ${field.label} es obligatorio`;

		if (field.type === "options" && field.options?.returnAll) {
			schema[field.name] = yup.object().shape({
				[field.options.key]: required
					? validator.required(req)
					: validator.notRequired(),
			});
		} else {
			schema[field.name] = required
				? validator.required(req)
				: validator.notRequired();
		}
	});
	return yup.object().shape(schema);
};

//Draggable
export function dragElement(elmnt:any, elmntForm:any) {
	let pos1 = 0,
		pos2 = 0,
		pos3 = 0,
		pos4 = 0;
	const dragg=document.getElementById("draggHead");
	if (dragg) {
		/* if present, the header is where you move the DIV from:*/
		dragg.onmousedown = dragMouseDown;
	} else {
		/* otherwise, move the DIV from anywhere inside the DIV:*/
		elmnt.onmousedown = dragMouseDown;
	}

	function dragMouseDown(e:any) {
		e = e || window.event;
		e.preventDefault();
		// get the mouse cursor position at startup:
		pos3 = e.clientX;
		pos4 = e.clientY;
		document.onmouseup = closeDragElement;
		// call a function whenever the cursor moves:
		document.onmousemove = elementDrag;
	}

	function elementDrag(e:any) {
		e = e || window.event;
		e.preventDefault();
		// calculate the new cursor position:
		pos1 = pos3 - e.clientX;
		pos2 = pos4 - e.clientY;
		pos3 = e.clientX;
		pos4 = e.clientY;
		// set the element's new position:
		if (
			elmnt.offsetTop >= 1 &&
			elmnt.offsetTop + elmntForm.clientHeight <= window.innerHeight
		)
			elmnt.style.top = elmnt.offsetTop - pos2 + "px";
		else if (
			(elmnt.offsetTop < 1 && pos2 < 0) ||
			(elmnt.offsetTop + elmntForm.clientHeight > window.innerHeight &&
				pos2 > 0)
		)
			elmnt.style.top = elmnt.offsetTop - pos2 + "px";

		if (
			elmnt.offsetLeft >= 1 &&
			elmnt.offsetLeft + elmntForm.clientWidth <= window.innerWidth
		)
			elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
		else if (
			(elmnt.offsetLeft < 1 && pos1 < 0) ||
			(elmnt.offsetLeft + elmntForm.clientWidth > window.innerWidth && pos1 > 0)
		)
			elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
	}

	function closeDragElement() {
		/* stop moving when mouse button is released:*/
		document.onmouseup = null;
		document.onmousemove = null;
	}
}
//Heigth Watch, Agrega un observer que cambia el tamaño del contenedor de las multilineas al
//cambiar de tamaño por la vista reducida
export let resizeObserver:any ;
export function watchHeigth(){
	function outputsize(textbox:any) {		
		const height = textbox[0].contentRect.height;
		const webLines = document.getElementById("webLines");
		
		if(webLines)
			webLines.style.height = height+"px";
	}	   
	   const textbox: HTMLElement = <HTMLElement>document.getElementById("webPhone")
	   resizeObserver =  new ResizeObserver(outputsize).observe(textbox);

}
export function disconnectResizeObserver(){
	console.log('resizeObserver',resizeObserver)
}
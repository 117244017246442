import { AuthModule } from "../modules/Auth";
import { INotification, NotificationModule } from "../modules/Notification";
import { TicketModule } from "../modules/Ticket";
import { AlertModule } from "@/store/modules/Alert";
import { AccountModule } from "../modules/Account";
import { InterfaceId, RoomUser } from "vue-advanced-chat";
import { IClient, Interfaces, InterfacesColor, ITicket } from "@/types/tickets";
import { DateTime } from "ts-luxon";
import { getRandomColor, getTypeFile } from "@/util/utils";
import { IRoom } from "@/types/messages";
import { env } from "@/config";

export function getInterfaceName(_interface: InterfaceId) {
	return Interfaces[_interface];
}

export function getInterfaceColor(_interface: InterfaceId) {
	return InterfacesColor[_interface];
}
function getNameRoom(client: IClient): string {
	const { name, ...props } = client.customer;
	if (!env.showFullName) return name;
	const fullName = [
		props.first_name,
		props.second_name,
		props.last_name,
		props.last_name2,
	]
		.filter(acc => acc)
		.reduce((acc, curr) => acc + `${curr} `, "")
		?.trim();
	return fullName?.length ? fullName : name;
}

export function formatRoomFromTicket(
	ticket: ITicket,
	pin = false,
	newestRoom = true,
	lastMessageDate = ""
): IRoom | null {
	if (!ticket || !ticket.last_msg) return null;

	const pinned = pin
		? pin
		: ticket.pinned?.indexOf(AuthModule.id) >= 0
			? true
			: false;

	const channelIndex = ticket.client[0].channels.findIndex(
		ch =>
			ch.channel === ticket.channel?._id &&
			(ticket.channel.interface !== "WHATSAPP_OFFICIAL" ||
				ch.service_id === ticket.thread)
	);
	const room: IRoom = {
		roomId: ticket._id,
		roomName:
			ticket.client.length === 1
				? getNameRoom(ticket.client[0])
				: ticket.subject,
		avatar: ticket.subject_picture,
		color: getRandomColor(),
		lastMessage: formatLastMessageFromTicket(ticket.last_msg),
		users: usersFromTicket(ticket),
		interface: ticket.channel.interface as any,
		interfaceName: ticket.channel.name,
		lastMessageDate: newestRoom ? ticket.last_msg_date : lastMessageDate,
		pinned: pinned,
		archived: ticket.archived ? ticket.archived : false,
		newestRoom: newestRoom,
		updatedAt: ticket.updatedAt,
		cId: ticket.client[0].channels[channelIndex]?.cId,
		replied: ticket.client[0].channels[channelIndex]?.replied,
		expireAt: ticket.client[0].channels[channelIndex]?.expireAt
			? new Date(ticket.client[0].channels[channelIndex]?.expireAt)
			: undefined,
	};

	return room;
}

export function usersFromTicket(ticket: ITicket): RoomUser[] {
	const users: RoomUser[] = [];
	ticket.client.forEach(cl => {
		users.push({
			_id: cl._id,
			username: cl.customer.name,
			avatar: cl.picture,
			identifications: cl.identification,
		});
	});
	ticket.agent.forEach(a => {
		users.push({
			_id: a._id,
			username: a.name,
			avatar: a.picture,
			status: {
				state: "online",
				lastChanged: "today, 14:30",
			},
		});
	});

	return users;
}
export function formatLastMessageFromTicket(
	last_msg: ITicket["last_msg"]
): IRoom["lastMessage"] {
	const messageTime = DateTime.fromISO(last_msg.date);
	const timestamp =
		messageTime.toLocaleString(DateTime.DATE_SHORT) ==
			DateTime.now().toLocaleString(DateTime.DATE_SHORT)
			? messageTime.toLocaleString(DateTime.TIME_SIMPLE)
			: messageTime.toLocaleString(DateTime.DATE_SHORT);
	const typeLmsg =
		last_msg.body.attachment &&
		getTypeFile(
			last_msg.body.attachment.url ?? "",
			last_msg.body.attachment.type
		);
	return {
		content: last_msg.body.text ?? typeLmsg.text,
		senderId: last_msg.client?._id ?? last_msg.agent?._id,
		username:
			last_msg.client?.customer?.name ?? last_msg.agent?.name ?? "unknown",
		timestamp: timestamp,
		icon: !last_msg.body.text ? typeLmsg.icon : undefined,
		origin: last_msg.origin,
	};
}

export function updateRoom(prev: IRoom, newTicket: ITicket): IRoom {
	const pinned = newTicket.pinned?.indexOf(AuthModule.id) >= 0 ? true : false;
	const channel = newTicket.client[0].channels.find(
		ch => ch.channel === newTicket.channel._id &&
			(newTicket.channel.interface !== "WHATSAPP_OFFICIAL" ||
				ch.service_id === newTicket.thread)
	);
	if (newTicket.channel.interface === "WHATSAPP_OFFICIAL" && channel) {
		prev.expireAt = new Date(channel.expireAt);
		prev.replied = channel.replied;
		prev.cId = channel.cId
	}

	prev.users = usersFromTicket(newTicket);
	prev.roomName =
		newTicket.client.length === 1
			? newTicket.client[0].customer.full_name ??
			newTicket.client[0].customer.name
			: newTicket.subject;
	prev.lastMessage = formatLastMessageFromTicket(newTicket.last_msg);
	prev.lastMessageDate = newTicket.last_msg_date;
	prev.archived = Boolean(newTicket.archived);
	prev.pinned = pinned;
	return prev;
}

export function closeTicket(roomId: string) {
	const ticket = TicketModule.Tickets.find(t => t._id === roomId);
	const account = AccountModule.Accounts.find(a => a._id === ticket?.account);
	const requiredTypification =
		account && account.force_typification ? account.force_typification : false;
	if (requiredTypification && !((ticket?.agent.length ?? 0) > 1)) {
		if (!(ticket?.tipificacion && ticket?.subtipificacion) && !ticket?.data) {
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: `Debe de seleccionar una tipificación y subtipificación para el ticket`,
				type: "error",
			});
			return;
		}
	}
	const params: Pick<
		INotification,
		| "description"
		| "action"
		| "handleAction"
		| "handleClose"
		| "textCancel"
		| "textBtn"
		| "rightIconBtn"
	> = {
		description: "",
		action: "",
	};
	const clientInfo = ticket?.client[0].channels[0];
	if ((ticket?.agent.length ?? 0) > 1) {
		params.description =
			"Este ticket está siendo compartido con más agentes, no puede cerrarlo, ¿Desea salir de la conversación?";
		params.action = `ticket-leave/${roomId}`;
		params.textBtn = "Abandonar ticket";
		params.textCancel = "Cerrar modal";
		params.handleAction = () => {
			TicketModule.ticketEnd({
				ticket: roomId,
				token: AuthModule.token ?? "",
				rate: false,
			});
			return true;
		};
	} else {
		params.description = whatsappInterfaces.includes(
			ticket?.channel?.interface ?? ""
		)
			? `¿Desea cerrar el ticket de ${clientInfo?.name} con el número ${ticket?.thread.split("@")[0]
			}?`
			: `¿Desea cerrar el ticket de ${clientInfo?.name}?`;
		params.action = "";
		params.textBtn = "Cerrar y encuestar";
		params.textCancel = "Cerrar ticket";
		params.rightIconBtn = "far fa-star";
		params.handleClose = () => {
			TicketModule.ticketEnd({
				ticket: roomId,
				token: AuthModule.token ?? "",
				rate: false,
			});
			return true;
		};
		params.handleAction = () => {
			TicketModule.ticketEnd({
				ticket: roomId,
				token: AuthModule.token ?? "",
				rate: true,
			});
			return true;
		};
	}
	NotificationModule.setNotification({
		_id: "",
		title: "Cerrar Ticket",
		agent: [],
		button: true,
		type: "danger",
		read: true,
		icon: "far fa-times-circle",
		createdAt: "",
		updatedAt: "",
		...params,
	});
}

export const whatsappInterfaces = ["WHATSAPP_PUP", "WHATSAPP_OFFICIAL"];

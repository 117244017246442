import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";

export interface MetaRouter {
	guard: boolean;
	assignedTicket: boolean;
}

export enum GuardRoles {
	GUEST = "guest",
	AGENT = "agent",
	CALLAGENT = "callagent",
}

export enum AuthStatus {
	LOADING_CHAT = 0,
	LOADING_CALL,
	LOADING_NONE,
	FULL_AUTHENTICATED,
	CHAT_AUTHENTICATED,
}

export type IRouteRecordRaw = Omit<RouteRecordRaw, "meta"> & {
	meta?: {
		guard?: GuardRoles;
		assignedTicket?: boolean;
		navbarType?: "light";
		noAutoNotify?: boolean;
	};
};

export type IRouteLocationNormalized = Exclude<
	RouteLocationNormalized,
	"meta"
> & {
	meta?: IRouteRecordRaw["meta"];
};

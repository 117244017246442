import { AuthModule } from "@/store/modules/Auth";
import { AlertModule } from "@/store/modules/Alert";
import {
	GuardRoles,
	IRouteLocationNormalized,
	IRouteRecordRaw,
} from "types/router";
import {
	RouteRecordRaw,
	createRouter,
	createWebHistory,
	NavigationGuardNext,
} from "vue-router";
import { omnichannelRoutes } from "../omnichannel/routes";
import { TicketModule } from "@/store/modules/Ticket";
import { PauseModule } from "@/store/modules/Pause";
import { env } from "@/config";
const DashboardLayout = () =>
	import(
		/* webpackChunkName: "Dashboard_Layout" */ "../views/Layout/DashboardLayout.vue"
	);

const AuthLayout = () =>
	import(/* webpackChunkName: "Auth_Layout" */ "../views/Pages/AuthLayout.vue");

const Login = () =>
	import(/* webpackChunkName: "Login" */ "../views/Pages/Login.vue");

const Profile = () =>
	import(
		/* webpackChunkName: "UserProfile" */ "../views/Pages/UserProfile.vue"
	);

// Components pages
const NotificationsList = () =>
	import(
		/* webpackChunkName: "Notifications" */ "../views/Widgets/NotificationsList.vue"
	);
const IncomingCall = () =>
	import(/* webpackChunckName: "CallCenterView" */ "../callcenter/main.vue");
const callHistory = () =>
	import(
		/* webpackChunckName: "CallCenterView" */ "../callcenter/callHistory.vue"
	);
const Lock = () =>
	import(/* webpackChunckName: "Lock" */ "../views/Pages/Lock.vue");
const callCenterMenu = {
	path: "/callcenter",
	component: DashboardLayout,
	redirect: "/callcenter/incoming",
	name: "CallCenter",
	children: [
		{
			path: "incoming",
			name: "Incoming",
			components: { default: IncomingCall },
		},
		{
			path: "history",
			name: "History",
			components: { default: callHistory },
		},
	],
	meta: {
		guard: GuardRoles.CALLAGENT,
	},
};

const componentsMenu = {
	path: "/widgets",
	component: DashboardLayout,
	redirect: "/widgets/notifications",
	name: "Widgets",
	children: [
		{
			path: "notifications",
			name: "Notificaciones",
			components: { default: NotificationsList },
		},
	],
	meta: {
		guard: GuardRoles.AGENT,
	},
};

const routes: Array<
	Exclude<RouteRecordRaw, "children"> & {
		children: IRouteRecordRaw[];
	}
> = [
	{
		path: "/",
		redirect: "/login",
		component: DashboardLayout,
		name: "Dashboards",
		children: [
			{
				path: "/user",
				name: "Profile",
				components: { default: Profile },
			},
		],
		meta: {
			guard: GuardRoles.AGENT,
		},
	},
	{
		path: "/",
		redirect: "/",
		component: AuthLayout,
		children: [
			{
				path: "/login",
				name: "Login",
				components: { default: Login },
				meta: {
					guard: GuardRoles.GUEST,
					noAutoNotify: false,
				},
			},
			{
				path: "/lock",
				name: "Lock",
				components: { default: Lock },
				meta: {
					guard: GuardRoles.AGENT,
					noAutoNotify: false,
				},
				beforeEnter() {
					if (!PauseModule.locked) return { name: "Omnichannel" };
					return true;
				},
			},
		],
	},
	componentsMenu,
	omnichannelRoutes,
	callCenterMenu,
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	linkActiveClass: "active",
	routes,
});

const resolveGuard = (role: GuardRoles, next: NavigationGuardNext) => {
	return {
		[GuardRoles.AGENT]: () => {
			AuthModule.role === GuardRoles.AGENT ? next() : next({ name: "Login" });
		},
		[GuardRoles.CALLAGENT]: () => {
			//console.log("Call agent", AuthModule.callRole === GuardRoles.CALLAGENT);
			AuthModule.callRole === GuardRoles.CALLAGENT
				? next()
				: next({ name: "Login" });
		},
		[GuardRoles.GUEST]: () => {
			AuthModule.role === GuardRoles.GUEST
				? next()
				: next({ name: env.onlyCallcenter ? "CallCenter" : "Omnichannel" });
		},
	}[role]();
};

router.beforeResolve(async (to: IRouteLocationNormalized, _, next) => {
	TicketModule.setOpenedSidebar(false);
	if (TicketModule.currentRoomID !== TicketModule.currentNewestRoom.id)
		await TicketModule.setActualRoom(TicketModule.currentNewestRoom.id);
	TicketModule.resetConfig();
	AlertModule.setAutoShow(!to.meta.noAutoNotify);
	await AuthModule.saveSession();
	resolveGuard(to.meta.guard ?? GuardRoles.GUEST, next);
});

export default router;

import {
	Module,
	Mutation,
	Action,
	VuexModule,
	getModule,
} from "vuex-module-decorators";
import { IRootState, store } from "@/store";
import { AxiosResponse } from "axios";
import { simpleCallCenterClient } from "@/conection/index";
import { Module as Mod } from "vuex";
import { AuthModule } from "./Auth";
import { AuthStatus } from "@/types/router";
import { AlertModule } from "@/store/modules/Alert";

export interface Iindicators {
	indicator: Iindicator | undefined;
	indicatorStatus: IindicatorStatus | undefined;
	Loading: boolean;
}
export interface Iindicator {
	cantidad: number;
	td: IPauseTime[] | IHistoryCall[] | IAgentStatistics[];
	th: ITittle[];
}
export interface IPauseTime {
	pausa: string;
	tiempo_pausa: string;
	tiempo_total: string;
}
export interface IHistoryCall {
	destino: string;
	fecha: string;
	hora: string;
	origen: string;
	tiempoHablando: string;
	tipo: string;
}
export interface IAgentStatistics {
	titulo: string;
	dato: string;
}
export interface ITittle {
	text: string | undefined;
	value: string;
}
export enum IindicatorStatus {
	PauseHistory = "Pausas del agente",
	HistoryCall = "Historial de llamadas",
	AgentStatistics = "Estadisticas de agente",
}
export interface IAbstract {
	type: IindicatorStatus;
	controller: string;
	cantidadName: string;
}
@Module({ dynamic: true, namespaced: true, store, name: "indicators" })
export class Indicators extends VuexModule implements Iindicators {
	constructor(module: Mod<ThisType<Iindicators>, IRootState>) {
		super(module);
	}
	indicator: Iindicator | undefined;
	indicatorStatus: IindicatorStatus | undefined;
	loading = false;
	get IndicatorStatus() {
		return this.indicatorStatus;
	}
	get Indicators() {
		return this.indicator;
	}
	get data() {
		return this.indicator?.td;
	}
	get headers() {
		return this.indicator?.th;
	}
	get Loading() {
		return this.loading;
	}
	@Mutation
	setLoading(loading: boolean) {
		this.loading = loading;
	}
	@Mutation
	setStatus(indicatorStatus: IindicatorStatus) {
		this.indicatorStatus = indicatorStatus;
	}
	@Mutation
	setIndicator(Indicator: Iindicator) {
		this.indicator = Indicator;
	}
	//Obtener pausas del agente
	@Action
	async updatePauseHistory() {
		await this.updateAbstract({
			type: IindicatorStatus.PauseHistory,
			controller: "consultPauseAgent.php",
			cantidadName: "cantidad_pausa",
		});
	}
	//Obtener la indicador de historial de llamadas
	@Action
	async updateCallHistory() {
		this.updateAbstract({
			type: IindicatorStatus.HistoryCall,
			controller: "consultHistorialLLamadas.php",
			cantidadName: "cantidad",
		});
	}
	//Obtener la indicador de historial de estadisticas de agente
	@Action
	async updateStatistics() {
		this.updateAbstract({
			type: IindicatorStatus.AgentStatistics,
			controller: "estadistica_agente.php",
			cantidadName: "cantidad",
		});
	}
	@Action
	//funcion abstracta
	async updateAbstract(abstract: IAbstract) {
		this.setStatus(abstract.type);
		this.setLoading(true);
		if (AuthModule.status == AuthStatus.FULL_AUTHENTICATED) {
			try {
				const response: AxiosResponse = await simpleCallCenterClient({
					method: "post",
					url: "controllers/" + abstract.controller,
					data: {
						idAgente: AuthModule.code ?? "",
						extension: AuthModule.extension ?? "",
					},
				});
				if (response?.status === 200) {
					this.setIndicator({
						cantidad: response.data?.[abstract.cantidadName],
						td: response.data?.td,
						th: response.data?.th,
					});
				} else {
					AlertModule.SOCKET_PUSH_NOTIFICATION({
						text: `Error: ${response.statusText} ,Code: ${response.status}`,
						type: "warning",
					});
				}
			} catch (error) {
				console.error(error, "errorINDICADORES");
			}
		}
		this.setLoading(false);
	}
}

export const IndicatorsModule = getModule(Indicators);

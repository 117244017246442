import { Field } from "@/types/components";
// import formValue from "@/form.json";
import { env } from "@/config";
import axios from "axios";

// eslint-disable-next-line @typescript-eslint/no-var-requires
// const file = require("../form.json");
export const dynamicForm: Promise<Field[]> | undefined = env.DynamicTypification
	? axios
			.get("/dynamicform.json")
			.then(form => form.data)
			.catch(err =>
				console.error("Archivo de formulario dinámico no generado", err)
			)
	: undefined;
/* export const userForm: Promise<Field[]> | undefined = env.DynamicUser
	? axios
			.get("/userform.json")
			.then(form => form.data)
			.catch(err =>
				console.error("Archivo de formulario de usuario no generado", err)
			)
	: undefined; */
/**Genera la información  */
// export const calldata: Promise<Field[]> | undefined = axios
// 		.get("/calldata.json")
// 		.then(form => form.data)
// 		.catch(err =>
// 			console.error("Archivo de información de llamada no generado", err)
// 		);
export const textMessages = {
	ROOMS_EMPTY: "Sin tickets",
	ROOM_EMPTY: "Seleccione un ticket!",
	NEW_MESSAGES: "No Leídos",
	MESSAGE_DELETED: "Este mensaje ha sido eliminado",
	MESSAGES_EMPTY: "Sin mensajes",
	CONVERSATION_STARTED: "Ticket abierto ",
	TYPE_MESSAGE: "Type message",
	SEARCH: "Search",
	IS_ONLINE: "is online",
	LAST_SEEN: "last seen ",
	IS_TYPING: "is writing...",
	CANCEL_SELECT_MESSAGE: "Cancel",
};
export const alertMessages = {
	NOT_CODE: "Su usuario no tiene código de agente CallCenter",
	NOT_PAUSE: `Call Center: El agente debe de estar en pausa`,
	READY_AGENT: "Call Center: Agente listo",
	ERROR_UPDATE: "Call Center: Actualización interrumpida",
	ERROR_SESSION:
		"Call Center: Sesión de agente invalida, vuelva a iniciar sesión",
	ERROR_CONECTION:
		"Call Center: Conexión perdida con el servidor, vuelva a iniciar sesión",
	ERROR_TYPIFICATION:
		"Call Center: Debe seleccionar una tipificación y subtipificación",
	ERROR_CALLUSER: "Call Center: Debe crear un agente para actualizar",
};
export const outCallsKey = "Saliente";
// export const dynamicForm:Field[] =env.DynamicTypification? JSON.parse( JSON.stringify(formValue)):[];

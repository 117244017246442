import { NotificationModule } from "@/store/modules/Notification";
import { computed, ref } from "vue";

export function useNotificationRef() {
	//State data
	const cancel = ref(false);
	const notification = ref("");
	const loading = computed(() => NotificationModule.loading);
	async function handleActions(str: string, id: string) {
		cancel.value = str === "read";
		notification.value = id;
		const [action, payload] = str.split("/");
		await NotificationModule.handleAction({ action, payload, id });
		notification.value = "";
	}
	return {
		loading,
		cancel,
		notification,
		handleActions,
	};
}

import { env } from "@/config";
import axios from "axios";

export const agentApi = axios.create({ baseURL: env.AgentApi });

// routes

export type FileResponse = { filename: string; url: string };

export const fetchUploadFile = (
	attachment: FormData,
	onProgress?: (progressEvent: ProgressEvent) => void
) =>
	agentApi.post<FileResponse>("/attachment", attachment, {
		onUploadProgress: onProgress,
	});

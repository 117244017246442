import { store } from "@/store";
import { AttachmentTypes, env } from "@/config";
import { MessageFilePayload } from "vue-advanced-chat";
import { Action, getModule, Module, VuexModule } from "vuex-module-decorators";
import { fetchUploadFile, FileResponse } from "@/conection/agent";
import { MessageModule } from "./Message";
import { AxiosResponse } from "axios";
import { ObjectId } from "@/util/ObjectId";

export interface Result<T> {
	success?: {
		response: T;
		status?: number | string;
	};
	error?: {
		message: string;
	};
}
@Module({ dynamic: true, namespaced: true, store, name: "files" })
export class Files extends VuexModule {
	uploading: unknown[] = [];

	static generateId(
		files: MessageFilePayload[],
		extraId = false
	): Result<string[]> {
		let error = null;
		const ids =
			files?.map(f => {
				if (f.size > env.MaxAttachmentSize * 1e6)
					error = `El archivo ${f.name}, excede el límite de tamaño de ${env.MaxAttachmentSize}MB`;
				return new ObjectId().toString();
			}) ?? [];
		extraId && ids.push(new ObjectId().toString());
		return error
			? { error: { message: error } }
			: { success: { response: ids } };
	}
	static getType(type: string): string {
		const [str] = Object.entries(AttachmentTypes).find(([, rgx]) =>
			rgx.test(type)
		) ?? ["image"];
		return str;
	}
	@Action
	async uploadFiles({
		files,
		ids,
	}: {
		files: MessageFilePayload[];
		ids: number[];
	}): Promise<AxiosResponse<FileResponse>[]> {
		const promises =
			files?.map((f, i) => {
				const formData = new FormData();
				formData.append(
					"attachment",
					f.blob,
					`${f.name}${!f.audio ? "." + f.extension : ""}`
				);
				return fetchUploadFile(formData, (event: ProgressEvent) => {
					const progress = Math.round((event.loaded * 100) / event.total);
					MessageModule.updateProgress({ a: progress, i: ids[i] });
				});
			}) ?? [];
		return await Promise.all(promises);
	}
	@Action
	async uploadSingleFile(
		file: MessageFilePayload
	): Promise<AxiosResponse<FileResponse>> {
		const formData = new FormData();
		formData.append(
			"attachment",
			file.blob ?? file,
			`${file.name}${!file.audio && file.extension ? "." + file.extension : ""}`
		);

		return fetchUploadFile(formData);
	}
}

export const FilesModule = getModule(Files);

<template>
	<div class="form-group">
		<slot name="label">
			<div class="d-flex">
				<label v-if="label" :class="labelClasses">
					{{ label }}
				</label>
				<base-button
					v-if="!errorMessage && preview"
					class="ml-auto bg-white border-0"
					link
					type="primary"
					size="sm"
					@click="$emit('preview', value)"
				>
					Previsualizar
				</base-button>
			</div>
		</slot>
		<div class="custom-file">
			<Field :name="name" v-slot="{ handleChange, handleBlur }">
				<input
					:id="name"
					type="file"
					class="ipt-hidden"
					@change="handleChange"
					@blur="handleBlur"
					multiple
				/>
			</Field>
			<label class="custom-file-label" :for="name">
				<small class="custom-file-text">
					{{ nameFile }}
				</small>
			</label>
		</div>
		<slot name="helpBlock">
			<div
				class="text-danger invalid-feedback"
				style="display: block"
				v-show="errorMessage"
			>
				{{ errorMessage }}
			</div>
		</slot>
	</div>
</template>
<script>
import { Field, useField } from "vee-validate";
import { toRef } from "vue";
export default {
	name: "file-input",
	components: {
		Field,
	},
	inheritAttrs: false,
	props: {
		preview: {
			type: Boolean,
			default: false,
		},
		validate: {
			type: Function,
		},
		validator: {
			type: Object,
			default: undefined,
		},
		label: {
			type: String,
		},
		labelClasses: {
			type: String,
			description: "Input label css classes",
			default: "form-control-label",
		},
		name: {
			type: String,
			required: true,
		},
		placeholder: {
			type: String,
			default: "Seleccione un archivo",
		},
	},
	computed: {
		nameFile() {
			return (
				this.value?.reduce(
					(acc, curr) => (acc ? `${acc};${curr.name}` : curr.name),
					""
				) ?? this.placeholder
			);
		},
	},
	watch: {
		value(value) {
			console.log("file: ", typeof value);
		},
	},
	setup(props) {
		const { value, meta, errorMessage } = useField(
			toRef(props, "name"),
			props.validator
		);
		return {
			value,
			meta,
			errorMessage,
		};
	},
};
</script>
<style>
.ipt-hidden {
	display: none;
}
.custom-file-text {
	width: 80%;
	display: block;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.custom-file-label {
	padding-top: 0.9rem;
}
.form-group .custom-file-label::after {
	content: "Buscar";
}
</style>

import {
	Module,
	Mutation,
	Action,
	VuexModule,
	getModule,
} from "vuex-module-decorators";
import { IRootState, store } from "@/store";
import { AlertModule } from "@/store/modules/Alert";
import { AxiosResponse } from "axios";
import { callCenterClient } from "@/conection/index";
import { parseChatPauseList, parseResponse } from "@/util/data";
import { Module as Mod } from "vuex";
import { env } from "@/config/index";
import { AuthModule } from "./Auth";
import { AuthStatus } from "@/types/router";
import { TicketModule } from "./Ticket";
import { Filters } from "@/types/control";

export interface IReasonList {
	active: boolean;
	createdAt: Date | string;
	descripcion: string;
	organization: string;
	razon: string;
	reset?: boolean;
	updatedAt: Date | string;
	__v: number;
	_id: string;
}
export interface IReasonPaused {
	id?: string;
	reason?: string;
	reset?: boolean;
}
export interface IPause {
	data: {
		[IStatus.CHATCENTER]: IPauseState;
		[IStatus.CALLCENTER]: IPauseState;
	};
	Status: IStatus;
	fetched: boolean;
	LoadedPause: boolean;
}
export interface IPauseState {
	loading?: boolean;
	isPaused?: boolean;
	reasonPaused: IReasonPaused;
	reasonsPaused?: IReasonList[];
}
export interface IPauseCall {
	title: string;
	value: string;
}

export enum IStatus {
	CHATCENTER = "CHATCENTER",
	CALLCENTER = "CALLCENTER",
}

@Module({ dynamic: true, namespaced: true, store, name: "pause" })
export class Pause extends VuexModule implements IPause {
	constructor(module: Mod<ThisType<IPause>, IRootState>) {
		super(module);
		const pause = JSON.parse(
			localStorage.getItem("PAUSE_CALL") ?? "{}"
		) as IReasonPaused;
		this.setCallreasonPaused(pause);
		this.setCallisPause(!!pause.id);
		const chatPause = JSON.parse(
			localStorage.getItem("PAUSE_CHAT") ?? "{}"
		) as IReasonPaused;
		this.setChatreasonPaused(chatPause);
		this.setChatisPause(!!chatPause.id);
	}
	isCall = false;
	Status: IStatus = IStatus.CHATCENTER;
	fetched = false;
	lock = false;
	data: IPause["data"] = {
		[IStatus.CHATCENTER]: { reasonPaused: {} },
		[IStatus.CALLCENTER]: { reasonPaused: {} },
	};
	LoadedPause = false;

	get locked() {
		return this.lock;
	}

	get reasons() {
		return this.data?.[this.Status]?.reasonsPaused;
	}

	get paused() {
		return this.data?.[this.Status]?.isPaused;
	}
	get loading() {
		return this.data?.[this.Status]?.loading;
	}
	get status() {
		return this.Status;
	}
	get reasonPause() {
		return this.data?.[this.Status]?.reasonPaused.reason;
	}
	@Mutation
	setLoadedPause(loadedPause: boolean) {
		this.LoadedPause = loadedPause;
	}
	@Mutation
	setIsCall(isCall: boolean) {
		this.isCall = isCall;
	}
	@Mutation
	setStatus(status: IStatus) {
		this.Status = status;
	}
	@Mutation
	setFetched(fetched: boolean) {
		this.fetched = fetched;
	}
	@Mutation
	setCallReasons(Lista: IReasonList[]) {
		this.data[IStatus.CALLCENTER].reasonsPaused = Lista;
		if (Lista.length)
			this.data[IStatus.CALLCENTER].reasonPaused.id = Lista[0]._id;
	}
	@Mutation
	SOCKET_SET_LISTA_PAUSAS(lista: IReasonList[]) {
		if (!env.onlyCallcenter) {
			this.data[IStatus.CHATCENTER].reasonsPaused = lista;
			if (lista.length) {
				this.data[IStatus.CHATCENTER].reasonPaused.id = lista[0]._id;
			}
		}
	}
	@Mutation
	setCallisPause(pause: boolean) {
		this.data[IStatus.CALLCENTER].isPaused = pause;
	}
	@Mutation
	setChatisPause(pause: boolean) {
		this.data[IStatus.CHATCENTER].isPaused = pause;
	}
	@Mutation
	setCallreasonPaused(reasonPaused: IReasonPaused) {
		localStorage.setItem("PAUSE_CALL", JSON.stringify(reasonPaused));
		this.data[IStatus.CALLCENTER].reasonPaused = reasonPaused;
	}
	@Mutation
	setLoading(loading: boolean) {
		this.data[this.Status].loading = loading;
	}
	@Mutation
	setLock(loading: boolean) {
		this.data[this.Status].loading = loading;
	}
	@Mutation
	setChatreasonPaused(reasonPaused: IReasonPaused) {
		localStorage.setItem("PAUSE_CHAT", JSON.stringify(reasonPaused));
		this.data[IStatus.CHATCENTER].reasonPaused = reasonPaused;
		this.lock = !!reasonPaused.reset;
	}
	@Mutation
	SOCKET_SET_PAUSADO(id: string) {
		this.data[IStatus.CHATCENTER].loading = false;
		this.data[IStatus.CHATCENTER].isPaused = true;
		const pause = this.data[IStatus.CHATCENTER].reasonsPaused?.find(
			r => r._id == id
		);
		const reasonPaused: IReasonPaused = {
			id: id,
			reason: pause?.razon,
			reset: pause?.reset,
		};
		localStorage.setItem("PAUSE_CHAT", JSON.stringify(reasonPaused));
		this.data[IStatus.CHATCENTER].reasonPaused = reasonPaused;
		if (!env.onlyCallcenter)
			AlertModule.SOCKET_PUSH_NOTIFICATION({
				text: `Pausado: ${this.data[IStatus.CHATCENTER].reasonPaused.reason}`,
				type: "warning",
			});
		if (reasonPaused.reset) {
			this.lock = true;
			TicketModule.setTickets({ filter: Filters.CLAIMED, tickets: [] });
		}
	}

	@Mutation
	SOCKET_SET_LISTO() {
		this.data[IStatus.CHATCENTER].isPaused = false;
		this.data[IStatus.CHATCENTER].loading = false;
		localStorage.setItem("PAUSE_CHAT", JSON.stringify({ id: "" }));
		this.data[IStatus.CHATCENTER].reasonPaused = { id: "" };
		this.lock = false;
	}

	@Mutation
	SOCKET_NOT_SET_LISTO() {
		this.data[this.Status].loading = false;
	}

	//Obtener la lista de  pausas
	@Action
	async updatePauseCallReason() {
		if (!this.fetched) {
			let pausesReason: IPauseCall[] = [];

			try {
				const response: AxiosResponse = await callCenterClient({
					method: "post",
					url: "controllers/getPauseReason.php",
					data: new URLSearchParams({
						extension: AuthModule.extension ?? "",
						agente: AuthModule.code ?? "",
					}),
				});
				if (response.data?.request_status === "OK") {
					pausesReason = response.data?.lista_pausas;
				}
			} catch (error) {
				console.error(error, "error");
			}
			this.setCallReasons(parseChatPauseList(pausesReason));
			this.setFetched(true);
		}
	}
	//Obtener el estado del agente, en pausa o listo y asigna una pausa generica
	@Action
	async updateAgentStatus() {
		if (AuthModule.status == AuthStatus.FULL_AUTHENTICATED) {
			try {
				const response: AxiosResponse = await callCenterClient({
					method: "post",
					url: "controllers/agent_status.php",
					data: new URLSearchParams({
						extension: AuthModule.extension ?? "",
						agente: AuthModule.code ?? "",
					}),
				});
				if (response.data?.request_status === "OK")
					if (response.data?.agent_status === 1 && !this.LoadedPause) {
						
						//Si regresa pausado, genera una pausa inicial para la vista
						this.setCallisPause(true);
						this.setCallreasonPaused({
							id: env.AdminID,
							reason: env.AdminPause,
						});
						this.data[IStatus.CALLCENTER].reasonsPaused?.push({
							active: true,
							createdAt: "",
							descripcion: env.AdminPause,
							organization: "",
							razon: env.AdminPause,
							updatedAt: "",
							__v: 0,
							_id: env.AdminID,
						});
						this.setLoadedPause(true);
					} else if (response.data?.agent_status.toString() === "0") {
						this.setCallisPause(false);
						this.setCallreasonPaused({ id: "" });
					}
			} catch (error) {
				console.error(error, "error");
			}
		}
	}

	@Action
	async pauseSupagent({ reason }: { reason: string }) {
		this.setCallisPause(true);
		this.setCallreasonPaused({
			id: "0",
			reason,
		});
		AlertModule.SOCKET_PUSH_NOTIFICATION({
			text: `Pausado: ${reason}`,
			type: "warning",
		});
	}

	@Action
	async pauseAgent(id: string) {
		console.log("ID---------->", id);
		try {
			const _reason = this.data[IStatus.CALLCENTER].reasonsPaused?.find(
				r => r._id == id
			)?.razon;
			this.setLoading(true);
			const response: AxiosResponse = await callCenterClient({
				method: "post",
				url: "controllers/setAgentStatus.php",
				data: new URLSearchParams({
					agente: AuthModule.code ? AuthModule.code : "",
					extension: AuthModule.extension ? AuthModule.extension : "",
					action: "1",
					reason: _reason ? _reason : id,
				}),
			});
			this.setLoading(false);
			const res = parseResponse(response);
			if (res.status == "error") {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: res.message,
					type: "error",
				});
				this.setCallisPause(false);
				this.setCallreasonPaused({ id: "" });
				return;
			} else {
				this.setCallisPause(true);
				this.setCallreasonPaused({
					id: id,
					reason: this.data[IStatus.CALLCENTER].reasonsPaused?.find(
						r => r._id == id
					)?.razon,
				});
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: `Pausado: ${this.data[IStatus.CALLCENTER].reasonPaused.reason}`,
					type: "warning",
				});
			}
		} catch (error) {
			console.error(error, "error");
		}
	}

	@Action
	async updateStatus() {
		const response: AxiosResponse = await callCenterClient({
			method: "post",
			url: "controllers/setAgentStatus.php",
			data: new URLSearchParams({
				agente: AuthModule.code ? AuthModule.code : "",
				extension: AuthModule.extension ? AuthModule.extension : "",
				action: "2",
				reason: "0",
			}),
		});
		return parseResponse(response);
	}

	@Action
	async readyAgent(payload = { update: true }) {
		try {
			const res = payload.update ? await this.updateStatus() : undefined;
			if (res?.status == "Error" && payload.update) {
				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: res.message,
					type: "error",
				});
				return;
			} else {
				//Quitar la pausa generica
				if (this.data[IStatus.CALLCENTER].reasonPaused == env.AdminID) {
					this.data[IStatus.CALLCENTER].reasonsPaused = this.data[
						IStatus.CALLCENTER
					].reasonsPaused?.filter(item => item._id !== env.AdminID);
				}
				this.setCallisPause(false);
				this.setCallreasonPaused({ id: "" });

				AlertModule.SOCKET_PUSH_NOTIFICATION({
					text: "Agente listo",
					type: "success",
				});
			}
		} catch (error) {
			console.error(error, "error");
		}
	}
}
export const PauseModule = getModule(Pause);

import { ICustomer } from "@/callcenter/omni";
export function fullName(c: Partial<ICustomer>): string | undefined {
	return [
		c.first_name,
		c.second_name,
		c.third_name,
		c.last_name,
		c.last_name2,
	].join(" ");
}

import {
	Action,
	getModule,
	Module,
	Mutation,
	VuexModule,
} from "vuex-module-decorators";
import { store } from "@/store";

export interface IPhone {
	destroy: boolean;
	key: number;
	pinKey: number;

}

@Module({ dynamic: true, store, name: "phone" })
export class Phone extends VuexModule implements IPhone {
	destroy = false;
	key = 0;
	pinKey = 0;

	get getDestroy() {
		return this.destroy;
	}
	get getKey() {
		return this.key;
	}
	get getPinKey() {
		return this.pinKey;
	}
	@Mutation
	setDestroy(destroy: boolean) {
		this.destroy = destroy;
	}
	@Mutation
	setKey(key: number) {
		this.key = key;
	}
	@Mutation
	setPinKey(pinKey: number) {
		this.pinKey = pinKey;
	}
	@Action
	endSipSession() {
		document.getElementById("closeSession")?.click();
	}
}

export const PhoneModule = getModule(Phone);

import {
	PartialUser,
	ITypification,
	IChannel,
} from "@/callcenter/omni";

export interface ICallData {
	ActiveCall: IActiveCall;
	Scoring: boolean; //Verifica si se esta evaluando una llamada
	fetched: boolean; //Sí ya empezo el polling
	User: PartialUser | undefined; //Usuario presentado en el cliente, form y card
	IsNewUser: boolean; //Bandera , dice si es un nuevo usuario
	LoadingSK: boolean;
	Typification: ITypification[] | undefined;
	TypificationFetched: boolean;
	Channels: IChannel[] | undefined;
	ChannelsFetched: boolean;
	ErrorAlert: boolean;
    saveCallType:ISaveCallType;
	enableControls: boolean;
	callDataCount: number;
	queueCalls:{ [key: string]: number;};
}
export interface IActiveCall {
	call_number: string;
	uniqueid: string;
	call_status: string;
	resultado: number;
	call_type: string;
	queue: string;

}
export enum ISaveCallType {
	single = "single",
	saveCall = "saveCall",
	deleteCall = "deleteCall",
}
import {
	getModule,
	Module,
	Mutation,
	VuexModule,
} from "vuex-module-decorators";
import { store } from "@/store";
import { TicketModule } from "./Ticket";
import { ITag } from "@/types/store";
import { IChannel } from "@/types/tickets";

export interface ItagView extends ITag {
	selected: boolean;
}

export interface IReplies {
	active: boolean;
	mask: string;
	message: string;
	_id: string;
}

export interface IPredefinedReplies {
	activate: boolean;
	replies: IReplies[];
	subject: string;
	_id: string;
}

export interface IAccount {
	active: boolean;
	agents: string[];
	channels: IChannel[];
	createdAt: string;
	name: string;
	organization: string;
	picture: string;
	predefined_replies: IPredefinedReplies[];
	tags: ITag[];
	umbral_sla_ter: number;
	umbral_sla_tmo: number;
	updatedAt: string;
	num_tickets_auto_assign?: number;
	force_typification?: boolean;
	watson: { assistant: { wsid: string; apikey: string } };
	__v: number;
	_id: string;
}

export interface IAccountModule {
	accounts: IAccount[];
}

@Module({ dynamic: true, store, name: "account" })
export class Account extends VuexModule implements IAccountModule {
	accounts: IAccount[] = [];

	get tags(): ItagView[] {
		const tags: ITag[] =
			this.accounts.find(a => {
				return a._id == TicketModule.currentTicket?.account;
			})?.tags ?? [];

		return tags
			.filter(t => t.activo)
			.map(t => ({
				...t,
				selected: TicketModule.currentTicket?.tags.includes(t._id) ?? false,
			}));
	}

	get topics(): IPredefinedReplies[] {
		return (
			this.accounts
				.find(a => {
					return a._id == TicketModule.currentTicket?.account;
				})
				?.predefined_replies.filter(pr => pr.activate) ?? []
		);
	}

	get channels(): IChannel[] {
		return this.accounts.reduce((acc, curr) => {
			return acc.concat(curr.channels);
		}, [] as IChannel[]);
	}

	get Accounts(): IAccount[] {
		return this.accounts;
	}

	@Mutation
	SOCKET_ACCOUNT_LIST(accounts: IAccount[]) {
		this.accounts = accounts;
	}
}

export const AccountModule = getModule(Account);

<template>
	<div
		class="softphone-btn"
		:class="computedClasses"
		@click.capture="onClick()"
	>
		<div class="icon" :style="{ width: size + 'px', height: size + 'px' }">
			<h2 v-if="isNumber" class="color">{{ iconMdi }}</h2>
			<svg v-else viewBox="0 0 24 24">
				<path :d="iconMdi"></path>
			</svg>
		</div>

		<span class="label">
			<slot />
		</span>
	</div>
</template>

<script>
import {
	mdiShare,
	mdiContentSave,
	mdiDelete,
	mdiPhone,
	mdiPhoneHangup,
	mdiMicrophone,
	mdiMicrophoneOff,
	mdiFullscreen,
	mdiFullscreenExit,
	mdiPhoneReturn,
	mdiAccountGroup,
	mdiPhoneOutgoing,
	mdiPhoneIncoming,
	mdiPhonePaused,
	mdiShareOff,
	mdiPhonePlus,
	mdiPhoneMinus 
} from "@mdi/js";

export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		icon: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isNumber: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: "45",
		},
	},
	computed: {
		computedClasses() {
			const typeClass = this.type || "default";
			return { [typeClass]: true, disabled: this.disabled };
		},
		iconMdi() {
			switch ((this.icon || "").toLowerCase()) {
				case "phone":
					return mdiPhone;
				case "phone-hangup":
					return mdiPhoneHangup;
				case "save":
					return mdiContentSave;
				case "delete":
					return mdiDelete;
				case "mute":
					return mdiMicrophoneOff;
				case "unmute":
					return mdiMicrophone;
				case "maximize":
					return mdiFullscreen;
				case "minimize":
					return mdiFullscreenExit;
				case "share":
					return mdiShare;
				case "phone-return":
					return mdiPhoneReturn;
				case "group":
					return mdiAccountGroup;
				case "phone-out":
					return mdiPhoneOutgoing;
				case "phone-in":
					return mdiPhoneIncoming;
				case "phone-pause":
					return mdiPhonePaused;
				case "shareoff":
					return mdiShareOff;
				case "phone-plus":
					return mdiPhonePlus;
				case "phone-remove":
					return mdiPhoneMinus;
				default:
					return this.icon;
			}
		},
		iconClasses() {
			return null;
		},
	},
	methods: {
		onClick(e) {
			console.log("ThisDisabled",this.disabled)
			if (this.disabled) return;
			this.$emit("click-event");
		},
	},
};
</script>

<style>
.softphone-btn {
	display: inline-block;
	--icon-bg: white;
	--icon-color: #619ad2;
	--label-color: #333;
	text-align: center;
	user-select: none;
}

.color {
	color: var(--icon-color);
}

.softphone-btn:not(.disabled) {
	cursor: pointer;
}

.softphone-btn:not(.disabled):hover .icon {
	transform: translate(0, -2px);
	box-shadow: 0 6px 4px 2px #0002;
}

.softphone-btn:not(.disabled):active .icon {
	box-shadow: 0 0px 4px #0002;
	transform: translate(0, 2px);
	background: linear-gradient(180deg, #0002, #0003 40%, #0001 80%, #0001, #fff4),
		linear-gradient(180deg, var(--icon-bg), var(--icon-bg));
}

.softphone-btn.danger {
	--icon-bg: #ee3c78;
	--icon-color: white;
}

.softphone-btn.success {
	--icon-bg: #7bcc91;
	--icon-color: white;
}

.softphone-btn.info {
	--icon-bg: #79bbff;
	--icon-color: white;
}

.softphone-btn.warning {
	--icon-bg: #eca71d;
	--icon-color: white;
}

.softphone-btn.primary {
	--icon-bg: #619ad2;
	--icon-color: white;
}
.softphone-btn.normal {
	--label-color: rgb(211, 76, 188);
	--icon-color: rgb(110, 101, 109);
}
.softphone-btn.disabled {
	--label-color: #bbb;
	--icon-color: #eee;
	cursor: default;
}

.softphone-btn .label {
	font-weight: bold;
	display: block;
	color: var(--label-color);
	user-select: none;
}

.softphone-btn .icon + .label {
	margin-top: 10px;
}

.softphone-btn .icon {
	display: grid;
	place-items: center;
	box-sizing: border-box;

	padding: 6px;
	margin: 0 auto;
	fill: var(--icon-color);
	background: linear-gradient(180deg, #fff2, #fff0),
		linear-gradient(180deg, var(--icon-bg), var(--icon-bg));
	border-radius: 100%;
	box-shadow: 0 2px 2px 2px #0002;
	transition: box-shadow 0.2s ease-out, transform 0.2s ease-out;
	will-change: box-shadow, transform;
}

.softphone-btn .icon > * {
	width: 80%;
	height: 80%;
}

.softphone-btn.disabled .icon {
	background: #ccc;
	box-shadow: 0 2px 2px 2px #0001;
}
</style>

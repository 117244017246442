<script setup lang="ts"></script>
<template>
	<div class="w-100">
		<el-skeleton animated>
			<template #template>
				<div>
					<el-skeleton-item
						variant="text"
						style="height: 10px; width: 25%"
					></el-skeleton-item>
				</div>

				<div>
					<el-skeleton-item
						variant="text"
						style="height: 10px; width: 60%"
					></el-skeleton-item>
				</div>
				<div>
					<el-skeleton-item
						variant="text"
						style="height: 10px; width: 45%"
					></el-skeleton-item>
				</div>
			</template>
		</el-skeleton>
	</div>
</template>

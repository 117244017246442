import * as yup from "yup";

/* export interface ISchemaShare {

} */

export const schemaShare = () =>
	yup.object().shape({
		agent: yup
			.string()
			.trim()
			.matches(
				/((\w+)(,|))+/,
				"Algo ha salido mal, por favor intenta de nuevo!"
			)
			.required("Es requerido por lo menos un agente!"),
	});

export const schemaAccount = () =>
	yup.object().shape({
		account: yup
			.string()
			.trim()
			.matches(
				/((\w+)(,|))+/,
				"Algo ha salido mal, por favor intenta de nuevo!"
			)
			.required("Tienes que seleccionar una cuenta!"),
	});

export const schemaStartTicket = () =>
	yup.object().shape({
		phone: yup
			.string()
			.trim()
			.matches(
				/^(\d{5,})$/,
				"El número solo puede contener digítos (al menos 5)!"
			)
			.required("Es requerido el número telefónico!"),
		channel: yup
			.string()
			.trim()
			.matches(
				/(\w+)/,
				"Algo ha salido mal, por favor intenta de nuevo!"
			)
			.required("Es requerido seleccionar un canal!"),
		account: yup
			.string()
			.trim()
			.matches(
				/(\w+)/,
				"Algo ha salido mal, por favor intenta de nuevo!"
			)
			.required("Es requerido seleccionar una cuenta!"),

	});

	export const schemaStartTicketAnyInterface = () =>
	yup.object().shape({
		client: yup
			.string()
			.trim()
			.matches(
				/(\w+)/,
				"Algo ha salido mal, por favor intenta de nuevo!"
			)
			.required("Es requerido seleccionar un cliente!"),
		channel: yup
			.string()
			.trim()
			.matches(
				/(\w+)/,
				"Algo ha salido mal, por favor intenta de nuevo!"
			)
			.required("Es requerido seleccionar un canal!"),

	});